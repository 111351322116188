import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const LabProj = () => {
  const { treepMedia, gymmyMedia, osagoMedia, beeoMedia } = useStaticQuery(graphql`
    query {
      treepMedia: file(relativePath: { eq: "lab-treep.png" }) {
        childImageSharp {
          fluid(maxWidth: 651, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      gymmyMedia: file(relativePath: { eq: "lab-gymmy.png" }) {
        childImageSharp {
          fluid(maxWidth: 651, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      osagoMedia: file(relativePath: { eq: "lab-osago.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      beeoMedia: file(relativePath: { eq: "lab-beeo.png" }) {
        childImageSharp {
          fluid(maxWidth: 651, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <section className="labproj labproj--shapes" id="projects">
      <div className="labproj-inner container">
        <header className="labproj-header">
          <h2 className="labproj-title text-22 sm:text-42 font-bold leading-none">Собственные проекты</h2>
          <p className="labproj-subtitle sm:text-22">
            Мобильные приложения, сайты или другие IT (или не совсем) сервисы, которые мы запустили
            самостоятельно или вместе с партнерами. Каждый из них – отдельное начинание со своим планами,
            бюджетами, штатом специалистов и аудиторией.
          </p>
        </header>

        <article className="labproj-unit labwork">
          <div className="labwork-body">
            <h3 className="labwork-title">Treep</h3>
            <div className="labwork-caption">
              <p>
                Статистика поисковых запросов по фразе «чем заняться» показывает, что этот вопрос очень остро
                стоит на востоке нашей страны.
              </p>
              <p>
                Но в то же время и на планирование выходного дня с ребенком в Москве уйдет несколько часов, и
                не факт, что составленный план будет оптимален.
              </p>
              <p>Сложив эти факты, мы делаем попытку решить эти проблемы.</p>
            </div>
          </div>
          <div className="labwork-media">
            <Img fluid={treepMedia.childImageSharp.fluid} />
          </div>
          <div className="labwork-footer">
            <a
              href="https://treep.ai/"
              target="_blank"
              rel="nofollow"
              className="btn btn--primary px-14 py-4 sm:py-5 sm:text-22"
            >
              Подробнее
            </a>
          </div>
        </article>

        <article className="labproj-unit labwork labwork--reversed">
          <div className="labwork-body">
            <h3 className="labwork-title">Gymmy</h3>
            <div className="labwork-caption">
              <p>
                Проект, созданный совместно с партнерами. В рамках нашего сотрудничества мы решили взять
                лучшее из американского сервиса FlexIt, адаптировать механику под отечественный рынок и
                запустить его аналог в Москве.
              </p>
              <p>
                Суть – предоставлять людям возможность заниматься в фитнес залах поминутно, не покупая
                абонемент или разовое занятие по фиксированной цене, даже если в клубе проведете всего 30
                минут.
              </p>
            </div>
          </div>
          <div className="labwork-media">
            <Img fluid={gymmyMedia.childImageSharp.fluid} />
          </div>
          <div className="labwork-footer">
            <a
              href="https://gymmy.fit/"
              target="_blank"
              rel="nofollow"
              className="btn btn--primary px-14 py-4 sm:py-5 sm:text-22"
            >
              Подробнее
            </a>
          </div>
        </article>

        <article className="labproj-unit labwork">
          <div className="labwork-body">
            <h3 className="labwork-title">OSAGO</h3>
            <div className="labwork-caption">
              <p>Оптимизировали подбор лучшего предложения по покупке полиса ОСАГО.</p>
              <p>
                На разработанном сайте вы можете оформить полис ОСАГО онлайн в течение 5 минут и получить на
                e-mail готовый полис, который нужно просто распечатать и положить в машину.
              </p>
            </div>
          </div>
          <div className="labwork-media">
            <Img fluid={osagoMedia.childImageSharp.fluid} />
          </div>
          <div className="labwork-footer">
            <a
              href="https://osago.one/"
              target="_blank"
              rel="nofollow"
              className="btn btn--primary px-14 py-4 sm:py-5 sm:text-22"
            >
              Подробнее
            </a>
          </div>
        </article>

        <article className="labproj-unit labwork labwork--reversed">
          <div className="labwork-body">
            <h3 className="labwork-title">BeeO</h3>
            <div className="labwork-caption">
              <p>
                Мы считаем, что каждый человек должен иметь возможность понимать, какого качества товары лежат
                на прилавках, не вычитывая сложные наименования химических веществ в длинном списке состава и
                самостоятельно разбираясь в их вредности.
              </p>
              <p>
                Поэтому мы запустили сервис BeeO. Приложение позволяет проверить качество продуктов питания по
                штрихкоду и выбрать альтернативу более высокого качества из рекомендаций.
              </p>
            </div>
          </div>
          <div className="labwork-media">
            <Img fluid={beeoMedia.childImageSharp.fluid} />
          </div>
          <div className="labwork-footer">
            {/*<a*/}
            {/*  href="https://beeo.app/"*/}
            {/*  target="_blank"*/}
            {/*  rel="nofollow"*/}
            {/*  className="btn btn--primary px-14 py-4 sm:py-5 sm:text-22"*/}
            {/*>*/}
            {/*  Подробнее*/}
            {/*</a>*/}
          </div>
        </article>
      </div>
    </section>
  )
}

export default LabProj
