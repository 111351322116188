import React from 'react'

import Layout from '../components/Layout'
import LabHero from '../components/LabHero'
import LabProj from '../components/LabProj'
import LabExp from '../components/LabExp'

const AppcraftLab = () => (
  <Layout
    title="AppcraftLab"
    content="AppcraftLab"
    keywords="разработка мобильных приложений, заказать мобильное приложение, мобильное приложение на заказ, стоимость мобильного приложения, стартап, продвижение"
    ogSiteName="AppCraft"
    ogTitle="Студия Appcraft | Разработка мобильных приложений для Android и iOS"
    ogDescription="Разработка мобильных продуктов от студии, которая создает и развивает собственные проекты. Эффективные решения для клиентов на iOS и Android, основанные на собственном опыте"
  >
    <LabHero />
    <LabProj />
    <LabExp />
  </Layout>
)

export default AppcraftLab
