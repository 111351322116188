import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import PhoneAnim from '../PhoneAnim'

const LabHero = () => {
  const { heroMedia } = useStaticQuery(graphql`
    query {
      heroMedia: file(relativePath: { eq: "labhero-media.png" }) {
        childImageSharp {
          fluid(maxWidth: 297, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <section className="labhero">
      <div className="labhero-inner container">
        <div className="labhero-main">
          <h1 className="labhero-title text-22 sm:text-42 font-bold leading-tight">AppCraft Lab</h1>
          <div className="labhero-content">
            <p>
              Разработчик мобильных приложений просто обязан делать собственные проекты, иначе он не будет
              понимать, как продукты запускаются, развиваются и эволюционируют на старте. А значит, и не
              сможет на себе прочувствовать все проблемы, с которыми сталкиваются молодые проекты.
            </p>
            <p>
              Поэтому мы открыли этот раздел лаборатории на нашем сайте, куда складываем все проекты и
              эксперименты, которые делаем сами.
            </p>
          </div>
          <div className="labhero-actions">
            <AnchorLink to="/appcraftlab#projects" className="btn btn--primary mr-10 px-10 py-4 text-22">
              Наши проекты
            </AnchorLink>
            <AnchorLink to="/appcraftlab#experiments" className="btn btn--primary px-10 py-4 text-22">
              Эксперименты
            </AnchorLink>
          </div>
        </div>
        <div className="labhero-media">
          <PhoneAnim poster={heroMedia.childImageSharp.fluid} className="labhero-phone" />
        </div>
      </div>
    </section>
  )
}

export default LabHero
