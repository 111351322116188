import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const LabExp = () => {
  const { pubgMedia, neuroMedia } = useStaticQuery(graphql`
    query {
      pubgMedia: file(relativePath: { eq: "lab-pubg.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      neuroMedia: file(relativePath: { eq: "lab-neuro.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <section className="labproj labproj--exp" id="experiments">
      <div className="container">
        <header className="labproj-header">
          <h2 className="labproj-title text-22 sm:text-42 font-bold leading-none">Эксперименты</h2>
          <p className="labproj-subtitle sm:text-22">
            Небольшие работы, не имеющие своей продуктовой составляющей. Тем не менее, каждый из проведенных
            экспериментов принес нам новые знания, опыт и увлекательное времяпрепровождение.
          </p>
        </header>

        <article className="labproj-unit labwork labwork--exp">
          <div className="labwork-body">
            <h3 className="labwork-title">Programmer Unknown’s Battleground</h3>
            <div className="labwork-caption">
              <p>
                Проект возник как результат размышлений над игровой площадкой, на которой могли бы
                тренироваться программисты.
              </p>
              <p>Некая среда, в которой можно каким-то образом соревноваться программистскими навыками.</p>
              <p>А что может быть нагляднее, чем игра?</p>
            </div>
          </div>
          <div className="labwork-media">
            <Img className="labwork-img" fluid={pubgMedia.childImageSharp.fluid} />
          </div>
          <div className="labwork-footer">
            <a
              href="https://appcraft.pro/blog/laboratoriya-2-programmer-unknowns-battleground/"
              target="_blank"
              rel="nofollow"
              className="btn btn--primary px-14 py-4 sm:py-5 sm:text-22"
            >
              Подробнее
            </a>
          </div>
        </article>

        <article className="labproj-unit labwork labwork--reversed labwork--exp">
          <div className="labwork-body">
            <h3 className="labwork-title">Нейроинтерфейсы</h3>
            <div className="labwork-caption">
              <p>
                Лабораторные эксперименты с нейроинтерфейсами с помощью ЭЭГ монитора для считывания активности
                мозга Emotiv Insight.
              </p>
              <p>
                Попытки дошли до интеграции с роботом, как наиболее выраженным визуально примером мысленного
                управления.
              </p>
            </div>
          </div>
          <div className="labwork-media">
            <Img className="labwork-img" fluid={neuroMedia.childImageSharp.fluid} />
          </div>
          <div className="labwork-footer">
            <a
              href="https://appcraft.pro/blog/laboratoriya-nejrointerfejsy/"
              target="_blank"
              rel="nofollow"
              className="btn btn--primary px-14 py-4 sm:py-5 sm:text-22"
            >
              Подробнее
            </a>
          </div>
        </article>
      </div>
    </section>
  )
}

export default LabExp
